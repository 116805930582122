<template>
	<main class="page-container">
		<!-- s: 판로TV 메인 배너 -->
		<div class="broadcast-banner">
			<swiper ref="swiperBanner" class="swiper" :options="swiperOptionBanner">
				<swiper-slide v-for="(item, idx) in bannerList" :key="idx">
					<picture>
						<source media="(max-width: 1328px)" :srcset="item.moImgPtUrl" />
						<source media="(min-width: 1329x)" :srcset="item.imgPtUrl" />
						<img :src="item.imgPtUrl" alt="배너 텍스트를 여기에 넣어주세요" />
					</picture>
					<!-- 상단 알림받기 설정 주석 2023-05-26 -->
					<!-- <div class="container">
						<toggle-switch :id="item.sortSeq" class="color-orange" :propModel="smsList" :value="item.programCd" label="방송 알림받기" @changefunc="smsListChg(item.programCd)" />
					</div> -->
				</swiper-slide>
			</swiper>
			<div class="swiper-controls">
				<button type="button" :class="isBannerAutoplay ? 'swiper-button-play' : 'swiper-button-stop'" @click="toggleBannerAutoplay()">
					<template v-if="isBannerAutoplay">재생</template>
					<template v-else>중지</template>
				</button>
				<div class="swiper-pagination-container">
					<div class="swiper-pagination" slot="pagination"></div>
				</div>
			</div>
			<div class="swiper-buttons-container">
				<div class="swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button-next" slot="button-next"></div>
			</div>
		</div>
		<!-- e: 판로TV 메인 배너 -->
		<!-- s: 판로TV 라이브 -->
		<section class="broadcast-section" v-if="liveList.length">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">판로TV 라이브</h3>
					<button type="button" class="boradcast-button-cal" @click="showBroadcastCalendarModal">
						<i class="icon-tv"></i>
						<span>방송편성표</span>
						<i class="icon-arrow-right"></i>
					</button>
				</div>

				<div class="broadcast-component broadcast-component-live">
					<swiper class="swiper" ref="mySwiper" :options="swiperOptionLive">
						<swiper-slide v-for="(item, idx) in liveList" :key="idx">
							<!--  BRC_STS_CD : 07(방송예정), 10(라이브), 20(VOD)       -->
							<broadcast-card :type="item.BRC_STS_CD" :watch="item.INQ_CNT" :vodLink="`/broadcast/${item.BRC_INFO_NO}/view`" :dateDay="item.BRC_SCHDG_BGNG_DT.slice(5, 10)" :dateDayWeek="dateDayWeek(item)" :dateTime="`${item.BRC_SCHDG_BGNG_DT.slice(11, 16)} ~ ${item.BRC_SCHDG_END_DT.slice(11, 16)}`" :img="`${item.BRC_RPRS_IMG_URL}`" :alt="item.BRC_TIT" :title="item.TITLE" :subDesc="item.BRC_TIT" :relLink="item.REL_LINK" @clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination" slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
				<!-- <div v-else class="no-results-found has-icon"><p class="text">현재 편성된 라이브 방송이 없습니다.</p></div> -->
			</div>
		</section>
		<!-- e: 판로TV 라이브 -->

		<!-- s: 하이라이트 영상 -->
		<section class="broadcast-section broadcast-section--bg">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">하이라이트 영상</h3>
					<nav class="page-nav">
						<ul class="nav-list">
							<li class="nav-item"><a href="/playground" class="router-link-active"></a></li>
							<li class="nav-item" :class="{ 'is-selected': shortsProgramCd == '00' || !shortsProgramCd }">
								<button type="button" class="nav-text" @click="getShortsList('')">전체</button>
							</li>
							<li v-for="(program, idx) in shortsProgramListSort" :key="idx" class="nav-item" :class="{ 'is-selected': shortsProgramCd === program.PROGRAM_CD }">
								<button type="button" class="nav-text" @click="getShortsList(program.PROGRAM_CD)">{{ program.PROGRAM_NM }}</button>
							</li>
						</ul>
					</nav>
				</div>
				<div class="broadcast-component broadcast-component-highlight">
					<div v-if="isEmptyShortsList(shortsList)" class="no-results-found has-icon is-large">
						<p class="text">현재 콘텐츠 준비중입니다.</p>
					</div>
					<swiper v-if="!isEmptyShortsList(shortsList)" class="swiper" ref="mySwiper" :options="swiperOptionShort">
						<swiper-slide v-for="(item, idx) in shortsList" :key="idx">
							<!--  BRC_STS_CD : 07(방송예정), 10(라이브), 20(VOD)       -->
							<broadcast-card-short-form :img="`${item.BRC_RPRS_IMG_URL}`" :alt="item.BRC_TIT" :brcInfoNo="item.BRC_INFO_NO" :playerUrl="item.PLAYER_URL[0]" :vrtYn="item.VRT_YN" :title="item.TITLE" :subDesc="item.BRC_TIT" :relLink="item.REL_LINK" @clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination" slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>
			</div>
		</section>
		<!-- e: 하이라이트 영상 -->

		<!-- s: 카테고리별 방송 -->
		<section class="broadcast-section">
			<div class="container">
				<div class="page-header">
					<h3 class="page-header-title">카테고리별 방송</h3>
					<nav class="page-nav">
						<ul class="nav-list">
							<li class="nav-item"><a href="/playground" class="router-link-active"></a></li>
							<li class="nav-item" :class="{ 'is-selected': programCd == '00' || !programCd }">
								<button type="button" class="nav-text" @click="getVODList('', '')">전체</button>
							</li>
							<li v-for="(program, idx) in programList" :key="idx" class="nav-item" :class="{ 'is-selected': programCd === program.PROGRAM_CD }">
								<button type="button" class="nav-text" @click="getVODList(program.PROGRAM_CD, program.PROGRAM_NM)">{{ program.PROGRAM_NM }}</button>
							</li>
						</ul>
					</nav>
				</div>
				<div class="broadcast-component broadcast-component-category">
					<div class="toggle-header-alarm">
						<!-- <toggle-switch :model="smsList" :value="programCd" class="color-orange" label="방송 알림받기" @changefunc="smsListChg2(programCd)" /> -->
						<toggle-switch :propModel="smsList" :value="this.programCd" id="switchAllim" class="color-orange" :label="this.programNm + ' 알림받기'" @changefunc="smsListChg2(programCd)" />
					</div>
					<swiper class="swiper" ref="mySwiper" :options="swiperOptionCate">
						<swiper-slide v-for="(item, idx) in vodList" :key="idx">
							<!--  BRC_STS_CD : 07(방송예정), 10(라이브), 20(VOD)       -->
							<broadcast-card type="20" :watch="item.INQ_CNT" :vodLink="`/broadcast/${item.BRC_INFO_NO}/view`" :img="`${item.BRC_RPRS_IMG_URL}`" :alt="item.BRC_TIT" :title="item.TITLE" :subDesc="item.BRC_TIT" :relLink="item.REL_LINK" @clickfunc="showBroadcastLiveModal(item.BRC_INFO_NO)" />
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination" slot="pagination"></div>
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</div>

				<broadcast-calendar-modal v-if="broadcastCalendar.show" />
				<broadcast-live-modal v-if="broadcastLive.show" :brcInfoNo="this.brcInfoNo" />
			</div>
		</section>
		<!-- e: 카테고리별 방송 -->
	</main>
</template>

<script>
import { ACT_DELETE_PROG_SMSUSER, ACT_GET_LIVE_VIDEO_LIST, ACT_GET_PROG_BANNER_LIST, ACT_GET_PROG_SMSUSER_LIST, ACT_INSERT_PROG_SMSUSER, ACT_INSERT_ALL_PROG_SMSUSER, ACT_DELETE_ALL_PROG_SMSUSER } from '../../../store/_act_consts';
import { MUT_SHOW_BROADCAST_CALENDAR_MODAL, MUT_SHOW_BROADCAST_LIVE_MODAL, MUT_SHOW_CONFIRM, MUT_SET_RETURN_ROUTE } from '../../../store/_mut_consts';
import { mapGetters } from 'vuex';
import ToggleSwitch from '../../../components/common/ToggleSwitch';
import BroadcastCard from '../../../components/broadcast/BroadcastCard';
import BroadcastCardShortForm from '@/components/broadcast/BroadcastCardShortForm';
import BroadcastCalendarModal from '../../../components/broadcast/BroadcastCalendarModal';
import BroadcastLiveModal from '../../../components/broadcast/BroadcastLiveModal';
import { ymdToFormat, getItems, isSuccess } from '../../../assets/js/utils';

export default {
	name: 'Broadcast',
	components: {
		ToggleSwitch,
		BroadcastCard,
		BroadcastCardShortForm,
		BroadcastCalendarModal,
		BroadcastLiveModal,
	},
	data: () => ({
		swiperOptionBanner: {
			loop: false,
			centeredSlides: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			slidesPerView: 1,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-banner .swiper-button-next',
				prevEl: '.broadcast-banner .swiper-button-prev',
			},
			pagination: {
				el: '.broadcast-banner .swiper-pagination',
				type: 'fraction',
				clickable: true,
			},
		},
		swiperOptionLive: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-component-live .swiper-button-next',
				prevEl: '.broadcast-component-live .swiper-button-prev',
			},
			breakpoints: {
				280: {
					slidesPerView: 1.2,
					spaceBetween: 20,
					// slidesPerView: 1,
				},
				500: {
					slidesPerView: 2.2,
					spaceBetween: 10,
					// slidesPerView: 2,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 4,
				},
			},
		},
		swiperOptionShort: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-component-highlight .swiper-button-next',
				prevEl: '.broadcast-component-highlight .swiper-button-prev',
			},
			breakpoints: {
				280: {
					slidesPerView: 1.2,
					spaceBetween: 20,
				},
				500: {
					slidesPerView: 2.2,
					spaceBetween: 10,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 4,
				},
			},
		},
		swiperOptionCate: {
			loop: false,
			slidesPerView: 4,
			spaceBetween: 0,
			navigation: {
				nextEl: '.broadcast-component-category .swiper-button-next',
				prevEl: '.broadcast-component-category .swiper-button-prev',
			},
			breakpoints: {
				280: {
					slidesPerView: 1.2,
					spaceBetween: 20,
				},
				500: {
					slidesPerView: 2.2,
					spaceBetween: 10,
				},
				801: {
					spaceBetween: 0,
					slidesPerView: 4,
				},
			},
		},
		isBannerAutoplay: false,
		isSlideReady: false,
		programList: [],
		liveList: [],
		vodList: [],
		tryCount: 8,
		brcInfoNo: 0,
		programCd: '',
		programNm: '전체방송',
		bannerList: [], //상단 프로그램 배너 목록
		smsList: [], //프로그램코드 별 사용자SMS발송여부 체크박스
		// toggleTitle: '전체방송 알림받기',

		shortsTotalCnt: 0,
		shortsList: [],
		shortsProgramCd: '01',
		shortsProgramList: [],
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('broadcast', ['broadcastCalendar', 'broadcastLive', 'returnBrcInfoNo', 'shortsBrcTyp']),
		swiper() {
			return this.$refs.mySwiper.$swiper;
		},
		vodProgramCd: function () {
			var res = '';
			for (var i = 0; i < this.smsList.length; i++) {
				if (this.smsList[i] == this.programCd) res = this.programCd;
			}
			return res;
		},
		shortsProgramListSort: function () {
			let list = [];
			this.shortsProgramList.forEach(element => {
				if(element.PROGRAM_CD != '04' && element.PROGRAM_CD != '05'){
					list.push(element);
				}				
			});
			return list;
		},
	},
	watch: {
		session: function (newVal, oldVal) {
			this.getUserSmsList(newVal.userId);
		},
	},
	created() {
		if (this.returnBrcInfoNo) {
			this.showBroadcastLiveModal(this.returnBrcInfoNo);
		}
	},
	mounted() {
		this.getLiveList();
		this.getShortsList('01');
		this.getBannerList();
		this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
		this.getUserSmsList(this.session.userId);

		setTimeout(() => {
			window.scrollTo(0, 0);
		});
	},
	methods: {
		dateDayWeek: function (item) {
			const week = ['일', '월', '화', '수', '목', '금', '토'];
			var date = new Date(item.BRC_SCHDG_BGNG_DT);
			return week[date.getDay()];
		},
		smsListChg(val) {
			if (typeof this.session.userId == 'undefined') {
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
				return false;
			}

			var deleteFlag = false;
			for (var i = 0; i < this.smsList.length; i++) {
				if (val == this.smsList[i]) {
					deleteFlag = true; //체크박스에서 입력된 데이터가 있으면 delete
				}
			}
			if (deleteFlag) {
				//삭제
				this.$store
					.dispatch(`broadcast/${ACT_DELETE_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				//등록
				this.$store
					.dispatch(`broadcast/${ACT_INSERT_PROG_SMSUSER}`, {
						userId: this.session.userId,
						programCd: val,
					})
					.then((res) => {
						this.getUserSmsList(this.session.userId);
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		toggleBannerAutoplay() {
			try {
				if (this.isBannerAutoplay) {
					this.$refs.swiperBanner.$swiper.autoplay.start();
					this.isBannerAutoplay = false;
				} else {
					this.$refs.swiperBanner.$swiper.autoplay.stop();
					this.isBannerAutoplay = true;
				}
			} catch (error) {
				console.error(error);
			}
		},
		smsListChg2(val) {
			if (typeof this.session.userId == 'undefined') {
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
					nofunc: () => {
						document.getElementById('switchAllim').checked = false;
					},
				});
				return false;
			}

			var deleteFlag = false;
			for (var i = 0; i < this.smsList.length; i++) {
				if (val == this.smsList[i]) {
					deleteFlag = true; //체크박스에서 입력된 데이터가 있으면 insert
				}
			}

			if (deleteFlag) {
				// 전체 삭제
				if (val == '00') {
					this.$store
						.dispatch(`broadcast/${ACT_DELETE_ALL_PROG_SMSUSER}`, {
							userId: this.session.userId,
						})
						.then((res) => {
							this.getUserSmsList(this.session.userId);
						})
						.catch((e) => {
							console.log(e);
						});
				} else {
					// 개별 삭제
					this.$store
						.dispatch(`broadcast/${ACT_DELETE_PROG_SMSUSER}`, {
							userId: this.session.userId,
							programCd: val,
						})
						.then((res) => {
							this.getUserSmsList(this.session.userId);
						})
						.catch((e) => {
							console.log(e);
						});
				}
			} else {
				// 전체 등록
				if (val == '00') {
					this.$store
						.dispatch(`broadcast/${ACT_INSERT_ALL_PROG_SMSUSER}`, {
							userId: this.session.userId,
						})
						.then((res) => {
							this.getUserSmsList(this.session.userId);
						})
						.catch((e) => {
							console.log(e);
						});
				} else {
					// 개별 등록
					this.$store
						.dispatch(`broadcast/${ACT_INSERT_PROG_SMSUSER}`, {
							userId: this.session.userId,
							programCd: val,
						})
						.then((res) => {
							this.getUserSmsList(this.session.userId);
						})
						.catch((e) => {
							console.log(e);
						});
				}
			}
		},
		getUserSmsList(userId) {
			//사용자SMS발송여부 체크박스 초기화
			this.smsList.splice(0);

			//로그인 정보가 세션에 존재하는 경우
			if (typeof this.session != 'undefined') {
				this.$store
					.dispatch(`broadcast/${ACT_GET_PROG_SMSUSER_LIST}`, {
						userId: userId,
					})
					.then((res) => {
						var items = getItems(res);
						for (var i = 0; i < items.length; i++) {
							// 알림받을 리스트와 프로그램 리스트 갯수가 같을때 && 처음 한번
							// 전체 알림 스테이터스 추가
							if (this.programList.length == items.length && i == 0) {
								this.smsList.push('00');
								if (typeof this.programCd == 'undefined') {
									this.programCd = '00';
								}
							}

							this.smsList.push(items[i].programCd);
						}
					})
					.catch((e) => {
						console.log(e);
					});
			}
		},
		getBannerList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_PROG_BANNER_LIST}`, {})
				.then((res) => {
					this.bannerList = getItems(res);
				})
				.catch((e) => {
					this.bannerList = [];
				});
		},

		getLiveList() {
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					sortCd: '04',
					pageIndex: 1,
					pageSize: 8,
					brcTyp: 2,
					rpYn: 'Y',
				})
				.then((res) => {
					this.programList = res.data.PROGRAM_LIST;
					this.liveList = res.data.LIST;

					if (this.programList) {
						this.getVODList('', '');
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getVODList(programCd, programNm) {
			this.programCd = programCd == '' ? '00' : programCd;
			this.programNm = programNm == '' ? '전체방송' : programNm;
			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					pageIndex: 1,
					brcTyp: 2,
					programCd: programCd,
					brcStsCd: '20',
				})
				.then((res) => {
					this.vodList = res.data.LIST;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getShortsList(shortsProgramCd) {
			this.shortsProgramCd = shortsProgramCd == '' ? '00' : shortsProgramCd;

			this.$store
				.dispatch(`broadcast/${ACT_GET_LIVE_VIDEO_LIST}`, {
					pageIndex: 1,
					brcTyp: this.shortsBrcTyp,
					programCd: shortsProgramCd,
					brcStsCd: '20',
				})
				.then((res) => {
					// console.log(res);
					this.shortsList = res.data.LIST;
					this.shortsProgramList = res.data.PROGRAM_LIST;

					if (this.shortsProgramCd == null || this.shortsProgramCd == '00') {
						this.shortsTotalCnt = res.data.VOD_CNT;
					}
				})
				.catch(console.error);
		},
		isEmptyShortsList(shortsList = []) {
			return shortsList.length < 1
		},

		showBroadcastCalendarModal() {
			this.$store.commit(`broadcast/${MUT_SHOW_BROADCAST_CALENDAR_MODAL}`, {});
		},
		showBroadcastLiveModal(brcInfoNo) {
			this.brcInfoNo = brcInfoNo;
			this.$store.commit(`broadcast/${MUT_SHOW_BROADCAST_LIVE_MODAL}`, {});
		},
	},
};
</script>
