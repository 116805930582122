<template>
	<article class="live-card live-card__highlight" :class="{ 'is-playing': isVideoPlay }">
		<a class="live-video-link" @click="onClickBrc($event)">
			<div class="live-video" ref="video">
				<div class="video-player" @mouseover="overVideo" @mouseout="outVideo">
					<img :src="img" :alt="alt" />
					<!-- :class="[{ 'is-large': vrtYn !== 'Y' }, { 'is-vertical': widthVal * 1.1 < heightVal }]"  -->
					<iframe 
						:id="'myplayer_' + brcInfoNo" 
						:src="playerUrl + '&enable_initialize_focus=false'" 
						class="video-player__src" 
						allow="encrypted-media;fullscreen" 
						allowfullscreen="" 
						webkitallowfullscreen="" 
						mozallowfullscreen="" 
						autoplay
					></iframe>
					<span class="video-player__mask"></span>
				</div>
				<div class="video-content">
					<div class="video-content-group">
						<p class="video-content-title">{{ subDesc }}</p>
						<p class="video-content-desc"></p>
					</div>
					<!-- <div class="video-content-reflink" v-if="!!relLink">
						<a :href="relLink" target="_blank" title="새창 열림" class="button">관련페이지</a>
					</div> -->
				</div>
			</div>
		</a>
	</article>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'BroadcastCardShortForm',
	props: {
		img: String,
		alt: String,
		brcInfoNo: Number,
		playerUrl: String,
		vrtYn: String,
		title: String,
		subDesc: String,
		relLink: String,
	},
	data: () => ({
		_controller: null,
		isVideoPlay: false,

		widthVal: null,
		heightVal: null,
	}),
	computed: {
		...mapGetters('broadcast', ['shortsBrcTyp']),
	},
	watch: {},
	created() {},
	mounted() {
		this.initVgController();
	},
	methods: {
		// event ========================================================================================
		overVideo() {
			this.isVideoPlay = true;
			this._controller.play(0);
		},
		outVideo() {
			this.pauseVideo();
		},

		onClickBrc(e) {
			this.$router.push(
				{ 
					name: 'ShortFormView', 
					params: { brcInfoNo: this.brcInfoNo } 
				}
			);
		},

		// custon fn ========================================================================================
		pauseVideo() {
			this.isVideoPlay = false;
			this._controller.pause();
		},

		initVgController() {
			try {
				this._controller = new VgControllerClient({
					target_window: document.getElementById(`myplayer_${this.brcInfoNo}`).contentWindow,
				});

				this._controller.on('error', (err) => {
					console.log('error: ' + err);
				});

				this._controller.on('loaded', () => {
					const { width, height, bitrate } = this._controller.get_video_info();
					this.widthVal = width;
					this.heightVal = height;
				});

				this._controller.on('ready', () => {
					this._controller.set_controls_activity(false);
					this._controller.set_control_visibility(false); // 내장 Controller 숨기:false, 숨김해제:true

					// 크롬정책상 영상 자동재생 전 음소거
					// this._controller.set_volume(50); 	// 볼륨 크기
					this._controller.mute(); // 음소거
					this._controller.pause(); // 영상 재생
				});
			} catch (err) {
				console.log(err);
				this.pauseVideo();
			}
		},
	},
};
</script>
